import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import BaseLayout from '../components/base'
import Logo from '../components/logo'
import { defaultLanguage, preferredLanguage } from './../lib/site-language'
import Footer from '../components/footer'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Main = styled.main`
  padding-top: 40px;
  padding-right: 60px;
  padding-left: 60px;
  padding-bottom: 80px;
  background: url("data:image/svg+xml,%3Csvg width='1500' height='161' viewBox='0 0 1500 161' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M36.25 85.7083L0 70.125V161H1500V0L1463.75 19.4792C1428.75 38.9583 1357.5 77.9167 1286.25 97.3958C1213.75 116.875 1142.5 116.875 1071.25 112.979C1035.62 111.031 1000 108.109 964.375 105.188C928.75 102.266 893.125 99.3438 857.5 97.3958C786.25 93.5 713.75 93.5 642.5 101.292C595 106.486 547.5 115.144 500 123.801C476.25 128.13 452.5 132.458 428.75 136.354C357.5 148.042 286.25 155.833 213.75 144.146C142.5 132.458 71.25 101.292 36.25 85.7083Z' fill='white'/%3E%3C/svg%3E%0A"),
    url("data:image/svg+xml,%3Csvg width='734' height='763' viewBox='0 0 734 763' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M355.215 0.120342C430.96 -2.73616 498.546 45.7241 555.251 96.0353C607.763 142.625 633.165 207.654 661.517 271.881C693.545 344.439 747.989 416.053 730.68 493.455C712.973 572.636 638.308 623.132 572.394 670.424C506.864 717.441 435.82 760.282 355.215 762.845C273.066 765.456 192.674 734.994 127.768 684.559C64.2672 635.216 2.42572 567.255 0.0438144 486.859C-2.23668 409.887 85.1299 364.207 116.189 293.746C142.325 234.453 124.939 160.97 165.845 110.72C213.156 52.602 280.338 2.94407 355.215 0.120342Z' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='367' y1='0' x2='367' y2='763' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%230094E7'/%3E%3Cstop offset='1' stop-color='%230097EC' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A"),
    linear-gradient(180deg, #00a6da 0%, #3fbaff 100%);
  background-repeat: no-repeat;
  background-position: 50% calc(100% + 5px), calc(100% + 200px) 75%, center;
  background-size: contain, 800px auto, 100%;
  width: 100%;
  height: 100%;
  color: #fff;
  flex-grow: 1;

  @media screen and (max-width: 600px) {
    padding-right: 20px;
    padding-left: 20px;
    background-position: 50% calc(100% + 5px), calc(100% + 100px) 75%, center;
    background-size: contain, 300px auto, 100%;
  }
`

const Heading = styled.div`
  font-family: 'Alegreya Sans', --apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 32px;
  font-weight: 500;
  margin-top: 48px;
  margin-bottom: 24px;
`

const Subtitle = styled.div`
  max-width: 400px;
`

const HomepageLink = styled((props) => <Link {...props} />)`
  margin-top: 30px;
  text-decoration: none;
  color: #fff;
  opacity: 0.8;
  display: inline-block;

  &:hover {
    opacity: 1;
  }
`

const titles = {
  en: 'Page not found',
  id: 'Halaman tidak ditemukan',
}

const subtitles = {
  en:
    'The page you requested cannot be found. Please check whether the address you entered is correct.',
  id:
    'Halaman yang Anda minta tidak dapat ditemukan. Harap cek bahwa alamat yang Anda masukkan benar.',
}

const homePageLinkLabels = {
  en: 'Back to Homepage',
  id: 'Kembali ke Beranda',
}

const NotFoundPage = (): JSX.Element => {
  const language = preferredLanguage()
  const title = titles[language] ?? titles[defaultLanguage]
  const subtitle = subtitles[language] ?? subtitles[defaultLanguage]
  const label =
    homePageLinkLabels[language] ?? homePageLinkLabels[defaultLanguage]

  return (
    <BaseLayout>
      <Container>
        <Main>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <Logo logoStyle="white" height={60} />
          <Heading>{title}</Heading>
          <Subtitle>{subtitle}</Subtitle>
          <HomepageLink to="/">{label}</HomepageLink>
        </Main>
        <Footer />
      </Container>
    </BaseLayout>
  )
}

export default NotFoundPage
